import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { buildLoadActivitiesUrl } from '../../../network/urls';
import { sendRetrieveRequest } from '../../../utils';
import { ActivityMap, ActivityResponse } from '../types';

export const loadAccountActivities = (
    actionName: string,
    processResultFunction: Function,
): Observable<{ result: ActivityMap[]; actionName: string; processResultFunction: Function }> => {
    const url = buildLoadActivitiesUrl();
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<ActivityResponse>) => {
            const { Activity } = response;
            const result = convertToCamel<ActivityMap[]>(Activity);
            return observableOf({ result, actionName, processResultFunction });
        }),
    );
};
